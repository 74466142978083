@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;

.product-details {
  min-width: 20rem;
  //width: 18rem;

  padding: 1.625rem 1rem;
  flex-direction: column;

  @include tablet {
    padding: 2.5rem 0;
    margin: 0 1.5rem;
  }

  @include desktop {
    max-width: 67.5rem;
    padding: 3rem 0;
    margin: 0 auto;
    display: flex;

  }

  &-top {
    padding-top: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include tablet{
      flex-direction: row;
    }

    &__link {
      @include link_base(0.5rem, 0.66rem);
      width: 2rem;

      @include tablet{
        width: 13rem;
      }

      &__text {
        padding-left: 0.3rem;
        transition: all 0.3s ease-in-out;
        display: none;

        @include tablet{
          display: flex;
        }

        &:hover{
          padding-left: .6rem;
        }
      }

      &__icon {
        font-size: 0.8rem;
      }
    }

    &__images {
      width: 100%;
      margin-bottom: 1.5rem;

      @include tablet{
        width: 48%;
        margin-bottom: 0;
      }

    }

    &__content {
      width: 100%;
      background-color: #FFFFFF;
      padding: 2rem;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.5rem;

      @include tablet {
        width: 48%;
      }

      &-heading {
        @include font_base(1.7rem,600);
        font-family: $primary-heading;

        @include tablet{
          font-size: 1.8rem;
        }

        @include desktop{
          font-size: 2rem;
        }
      }

      &-paragraph {
        @include font_base(1rem,500);
        line-height: 1.4rem;
        letter-spacing: 0.02rem;
        margin: 1.5rem 0;
      }

      &-subHeading {
        @include font_base(1.3rem,600);
        font-family: $primary-heading;

        @include tablet{
          font-size: 1.4rem;
        }

        @include desktop{
          font-size: 1.5rem;
        }
      }

      &-variant {
        margin: 1.2rem 0 1rem 0;
        font-weight: 500;

        &--span {
          font-family: $primary-heading;
          font-size: 1.2rem;
        }
      }

      &-price {
        margin: 1.5rem 0 0 0;
        display: flex;
        justify-content: flex-end;
      }

      &-purchase {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        &__button {
          background-color: #02558b;
          border: 2px solid #E42D9F;
          outline: none;
          border-radius: 2.5rem;
          font: inherit;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: .5rem 1.5rem;
          cursor: pointer;
          transition: all .3s ease-in-out;
          width: 100%;

          @include tablet{
            width: 11rem;
          }

          &:hover {
            background-color: #33678f;
          }

          &:disabled {
            cursor: not-allowed;
            background-color: #DADADA;
            color: rgb(118,118,118);
          }
        }
      }

      &-variant__select {
        width: 100%;
        height: 2.2rem;
        border-radius: 3rem;
        @include font_base(1.1rem, 500);
        font-family: $primary-font;
        outline: none;
        background: #FAFAFA url("../../assets/icons/arrow_drop_down-24px.svg") no-repeat right;
        -webkit-appearance: none;
        background-position-x: calc(100% - 1rem);
        cursor: pointer;
        padding: 0 1rem;

        &:active {
          border: 1px solid #E42D9F;
        }
        &:focus {
          border: 1px solid #E42D9F;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      &-list {
        margin: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
      }
    }
  }
  &-bottom {
    background-color: #FFFFFF;
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.5rem;

    &__heading {
      @include font_base(1.5rem,600);
      font-family: $primary-heading;
    }

    &__paragraph {
      @include font_base(0.75rem, 500);
      line-height: 1.7rem;
      margin-top: 1.3rem;
      letter-spacing: 0.02rem;

      @include tablet{
        font-size: 1rem;
      }

      @include desktop{
        font-size: 1.1rem;
      }
    }
  }
}