@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.image-container {
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  align-items: center;


  &-main {
    min-height: 316px;
    max-width: 420px;
    height: 97vw;
    min-width: 314px;
    width: 98vw;
    padding: 3rem;
    max-height: 422px;

    @include tablet{
      height: 54.6vw;
      width: 54.4vw;
      min-width: 418px;
      min-height: 420px;
    }

    &__img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-thumbs {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%;

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 1rem 0 2rem 0;
    }
  }
}