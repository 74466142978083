@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;

.catalog {
  min-width: 16.75rem;
  margin: 0 1.5rem;
  padding: 1.625rem 0;


  @include tablet {
    padding: 2.5rem 0;
  }

  @include desktop {
    max-width: 67.5rem;
    padding: 3rem 0;
    margin: 0 auto;
    display: flex;
  }
}