@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Medium.woff2') format('woff2'),
        url('../assets/fonts/Oswald-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Regular.woff2') format('woff2'),
  url('../assets/fonts/Oswald-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraLight.woff2') format('woff2'),
  url('../assets/fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Light.woff2') format('woff2'),
  url('../assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.woff2') format('woff2'),
  url('../assets/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

