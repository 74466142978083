@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.product {
  display: flex;
  padding: .8rem;
  border: 1px solid #FEFEFE;
  margin-bottom: 2rem;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%);
  align-items: center;
  justify-content: space-between;
  max-width: 276px;
  height: 34rem;
  border-radius: .5rem;

  @include tablet {
    height: 34rem;
  }

  @include  desktop {
    flex-direction: row;
    height: 21rem;
    max-width: 24.5rem;
  }

  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-action {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;

    @include desktop {
      width: 55%;
      height: 80%;
    }
  }

  &-image {
    height: 14rem;

    @include desktop {
      width: 45%;
      height: 83%;
    }

    &__src {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-bottom: 1rem;

    &__heading {
      text-decoration: none;
      color: #323232;

      &-text {
        @include label_header;
        margin-bottom: 1.2rem;
      }
    }

    @include desktop {
      align-items: flex-end;
    }


    &__description {
      @include description;
      display: flex;
      align-items: baseline;

      @include desktop {
        line-height: 1.3rem;
        font-size: 0.8rem;
      }

      &--price {
        align-self: flex-end;
        margin-top: auto;
      }
      &--span {
        @include label_header;
        font-size: 1rem;
        margin-right: .3rem;
      }
    }

    &__link {
      @include link_base(0.8rem, 0.86rem);
      width: 100%;

      &__icon {
        font-size: .8rem;
      }

      &__text {
        margin-right: .3rem;
        transition: all .3s ease-in-out;

        &:hover {
          margin-right: .6rem;
        }
      }
      &:hover {
        background-color: #33678f;
      }
    }
  }

}