@use "../../partials/variables" as *;
@use "../../partials/globals" as *;
@use "../../partials/mixins" as *;

.modal {
  visibility: hidden;
  display: flex;
  justify-content: center;


  &-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $primary-black;
    opacity: 0.7;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;
  }


  &-body {
    position: fixed;
    background-color: #FFFFFF;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // width: 100%;
    // height: 100vh;


    display: flex;
    flex-direction: column;
    z-index: 2;
    justify-content: center;

    opacity: 0;

    @include tablet {
      width: 42rem;
      height: 22rem;
      margin: 0 auto;
      border-radius: 0.5rem;
      box-shadow: 0 2px 5px rgba(19, 24, 44, 0.1);
      border-radius: 0.5rem;
      top: 20%;
      transition: opacity .15s, visibility 0.15s, transform 0.15s;
    }

    &--xl {
      @include tablet {
        height: 31rem;
      }
    }
  }

  &--open {
    visibility: visible;
    display: flex;
    justify-content: center;
  }

  &--open  & {
    &-body {
      opacity: 1;
      overflow-y: auto;
      @include tablet {
        transform: translateY(-8px);
      }
    }
  }

  &__close-button {
    padding: 0;
    border: none;
    border-radius: 0;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 2rem;
    right: 1.3rem;
    background-color: #FFFFFF;
  }

  &__close-icon {
    background: url('../../assets/icons/close-24px.svg') no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}