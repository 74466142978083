@use './variables' as *;

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin font_base($fontSize, $fontWeight) {
  font-weight: $fontWeight;
  font-size: $fontSize;
}

@mixin header {
  @include font_base(1.4rem, 700);
  font-family: $primary-heading;
  
  @include tablet {
    font-size: 2.5rem;
  }
}

@mixin label_header {
  @include font_base(1.1rem, 600);
  font-family: $primary-heading;
}

@mixin paragraph {
  @include font_base(0.8rem, 400);
  line-height: 1.2rem;
  color: $primary-black;
  
  @include tablet {
    font-size: 0.89rem;
    line-height: 1.6rem;
  }
  
  @include desktop {
    line-height: 1.6rem;
  }
}

@mixin description {
  @include font_base(0.8rem, 500);
  line-height: 1.2rem;
  color: #717171;

  @include tablet {
    font-size: 0.89rem;
    line-height: 1.6rem;
  }

  @include desktop {
    line-height: 1.6rem;
  }
}


@mixin link_base($topbottom, $leftright) {
  padding:  $topbottom $leftright;
  font: inherit;
  color: #FFFFFF;
  background-color: #02558b;
  border: 2px solid #E42D9F;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  border-radius: 2.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

@mixin auth_button($background,$background_hover) {
  padding: .5rem 1rem;
  border-radius: 2.5rem;
  outline: none;
  cursor: pointer;
  background-color: $background;
  color: $background_hover;
  border: 1px solid $background_hover;
  font: inherit;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    border: 1px solid #3e84b9;
    background-color: #3e84b9;
    color: #FFFFFF;
  }
}

@mixin h1 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: $primary-heading;
  
  @include tablet {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@mixin form {
  display: flex;
  flex-direction: column;
  
  
}

