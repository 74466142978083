@use '../../partials/mixins' as *;
@use "../../partials/variables" as *;

.filters-list {
  margin: 1rem 0 1.5rem 0;
  background-color: #FFFFFF;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%);
  width: 100%;
  max-width: 276px;
  border-radius: .5rem;
  opacity: 0;
  visibility: hidden;
  height: 0;
  max-height: 1000rem;
  transition: transform 0.1s linear, visibility 0.05ms ease-out, opacity 0.05s ease-out;
  transform: translateY(8px);

  @include desktop {
    width: 87%;
    margin-top: 1.5rem;
    opacity: 1;
    visibility: visible;
    height: auto;
    transform: translateY(0);
  }

  &--open {
    opacity: 1;
    visibility: visible;
    height: auto;
    transform: translateY(0);
  }
}
