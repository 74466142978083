@use './typography' as *;

// Breakpoints
$tablet: 768px;
$desktop: 1280px;


// Fonts
$primary-font: 'Poppins';
$primary-heading: 'Oswald';


// Colors 
$primary-black: #323232;
