@use "../../partials/variables" as *;
@use "../../partials/globals" as *;
@use "../../partials/mixins" as *;

.cartSummary {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #E6E4E4;

  &__container {
    display: flex;
    flex-direction: column;
    width: 65%;

    &__text {
      @include font_base(0.9rem,500);
      margin: 0.5rem;
      color: #47525D;
    }
  }

  &__subTotal {
    display: flex;
    width: 35%;
  }
}