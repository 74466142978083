@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.thumbnail-container {
  height: 5.6rem;

  @include desktop{
    height: 7.82rem;
  }

  &__image {
    object-fit: cover;
    height: 100%;
    cursor: pointer;
  }
}
